import React, { Component } from 'react';
import {Input} from "reactstrap";

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <h1>Hello, world!</h1>
        <p>Welcome to Tomato Paste</p>
        <p>You can here upload your codes and share it with your friends (or use them in your projects!).<br/>
            This project was made by Asp.net core and React and ReactStrap!<br/>
            You can see APIs <a href={'swagger'} target={'_blank'}>here</a>.</p>
          <br/>
          <br/>
          <b>Thanks, <a target={'_blank'} href={"https://amirrezashams.ir"}>Amirreza Shams</a></b>
      </div>
    );
  }
}
