import React, {Component, useEffect, useState} from 'react';
import {
    Container,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    Row,
    InputGroup,
    InputGroupAddon
} from "reactstrap";
import {PasteEditor} from "./PasteEditor";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {Input} from "@material-ui/core";

export const Show = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [paste, setPaste] = useState('hello');
    let {id} = useParams()
    useEffect(() => {
        axios.get('Paste/' + id).then((response) => {
            setPaste(response.data);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            setError(true);
        });
    }, []);

    return <>
        {loading ? <p>Loading <Spinner/></p> : (error ? "Error Fetching Data" : <Container>
            <PasteEditor readonly={true} name={paste.name} language={paste.language}
                         body={paste.body}/>

            <hr/>
            <div className={'mb-5'}>
                <Button className={'m-2'} onClick={() => window.open(window.location.origin+"/paste/"+id+"/source", '_blank').focus()} color="success">Open The Code</Button>
            </div>

        </Container>)}


    </>
}
