import React, {Component, useState} from 'react';
import {Container,Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {PasteEditor} from "./PasteEditor";
import axios from "axios";
import {useHistory} from "react-router-dom";

export const Create = () => {
    const history = useHistory();
    const [modal, setModal] = useState(false);
    const [modalMsg, setModalMsg] = useState(['','']);

    const toggle = () => setModal(!modal);
    const save = (name, body, language) => {
        axios.post('/paste', {
            Name: name,
            body: body,
            language: language
        })
            .then(function (response) {
                history.push('/code/'+response.data.id);
            })
            .catch(function (error) {
                setModalMsg(error.response.data.title);
                toggle();
            });
    }
    
    return <><Container>
        <PasteEditor readonly={false} saveFunction={save} saveText={'save'} name={''} language={'python'}
                     body={'# write your code here'}/>
    </Container>
        <div>
            <Modal isOpen={modal} toggle={toggle} open>
                <ModalHeader toggle={toggle}>Failed</ModalHeader>
                <ModalBody>
                    {modalMsg}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div></>;
}
