import React, {Component, useState} from 'react';
import Editor from "@monaco-editor/react";
import {Row, Container, Input, FormGroup, Col, Label, Button} from 'reactstrap';

export const PasteEditor = (props) => {
    const [language, setLanguage] = useState(props.language);
    const [name, setName] = useState(props.name);
    const [body, setBody] = useState(props.body);
    
    function changeLanguage(e) {
        setLanguage(e.target.value);
    }

    return (
        <Container>

            <Row className='m-3'>
                <div className='col-12 col-md-6'>
                    <Label>Name</Label>
                    <Input className={'w-100'} type='text' disabled={props.readonly} value={name} onChange={(e)=>setName(e.target.value)}/>
                </div>
                
                <div className='col-12 col-md-6'>
                    <FormGroup>
                        <Label for="exampleSelect">Language</Label>
                        <Input type="select" value={language} name="select" onChange={changeLanguage} disabled={props.readonly}>
                            <option>text</option>
                            <option>javascript</option>
                            <option>java</option>
                            <option>python</option>
                            <option>csharp</option>
                            <option>xdot</option>
                            <option>koka</option>
                            <option>boogie</option>
                            <option>chalice</option>
                            <option>dafny</option>
                            <option>formula</option>
                            <option>smt2</option>
                            <option>specsharp</option>
                            <option>z3python</option>
                            <option>html</option>
                            <option>markdown</option>
                            <option>ruby</option>
                        </Input>
                    </FormGroup>
                </div>
            </Row>

            {props.readonly ? null : <Row className={'mb-5 mr-2 ml-2'}>
                <div className={'col-12'}>
                    <Button onClick={()=>props.saveFunction(name,body,language)} color="success" className={'w-100'} outline={true}>{props.saveText}</Button>
                </div>
            </Row>}

            <Editor
                options={{readOnly: props.readonly}}
                defaultLanguage={props.language}
                height="70vh"
                value={body}
                onChange={(e)=>setBody(e)}
                language={language}
                defaultValue={props.body}
            />
        </Container>
    );
}
